@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Lato, system-ui, sans-serif;
}

sl-tab-group {
  --track-color: #FFFFFF00;
  --track-width: 4px;
  --sl-font-sans: Lato, system-ui, sans-serif;
  --sl-font-weight-semibold: 700;
}

sl-tab-group::part(active-tab-indicator) {
  border-radius: 4px;
}

sl-tab {
  font-weight: bold;
}

sl-carousel {
  --aspect-ratio: 1/1;
}

sl-carousel-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

@media (prefers-color-scheme: light) {
  sl-tab-group {
    --indicator-color: #52C2C8;
    --sl-color-primary-600: #000000;
  }

  sl-tab::part(base):hover {
    color: #000000;
  }
}

@media (prefers-color-scheme: dark) {
  sl-tab-group {
    --indicator-color: #43989E;
    --sl-color-primary-600: #FFFFFF;
  }

  sl-tab::part(base):hover {
    color: #FFFFFF;
  }
}

@layer utilities {
  .scrollbar-none {
    scrollbar-width: none;
  }
  
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }
}